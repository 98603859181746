import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Store } from "@ngxs/store";
import { SetUser, SetUserLocale } from "src/store/app/actions/set-user.action";
import { SetUsername } from "src/store/app/actions/set-username.action";
import {
    SetMyFormsHours,
    SetUserDailyHours,
    SetUserHours,
} from "src/store/app/actions/set-user-hours.action";
import {
    SetUserCheckIn,
    SetUserClockingRecords,
} from "src/store/app/actions/set-user-check-in.action";
import { appConstants } from "src/environments/constants";
import * as moment from "moment";
import { LoadUsersPagination } from "src/store/users/actions/load-users-pagination";
import { LoadUsers } from "src/store/users/actions/load-users.action";
import { ClearUsers } from "src/store/users/actions/clear-users.action";
import { ErrorResponseModel } from "src/app/services/response-models/error.response.model";
import { Form, FormsResponseModel } from "src/app/services/response-models/form.response.model";
import { ToastService } from "src/app/services/toast.service";
import {
    GeneralSuccessResp,
    SendFileToBucketResponseModel,
    SuccessAdd,
} from "src/app/services/response-models/companies.response.model";
import { LoadUserActiveSubscriptions } from "src/store/users/actions/load-selected-user.action";
import { DateTime } from "src/app/employee/forms/form-field-types/date-fields/DateTime";
import {
    ClockingRecord,
    ClockingRecordsResponseModel,
    GetUserByApiKey,
    UpdateUserResponseModel,
    User,
    UserHoursResponseModel,
    UserReponseModel,
} from "src/app/services/response-models/user.response.model";
import { SumTimeEntriesPipe } from "src/app/shared/pipes/sum-time-entries.pipe";
import { sentryCaptureException } from "src/app/utility/errors";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UsersService {
    private readonly getUsersUrl: string = `${environment.apiBaseUrl}users`;
    private readonly getCurrentUserUrl: string = `${environment.apiBaseUrl}users/me`;
    private readonly getUserHoursUrl: string = `${environment.apiBaseUrl}employee_hours`;
    private readonly checkInURL: string = `${environment.apiBaseUrl}clocking_records`;
    private readonly formsUrl: string = `${environment.apiBaseUrl}forms`;

    constructor(
        private http: HttpClient,
        private store: Store,
        private dateTimeService: DateTime,
        private toastService: ToastService
    ) {}

    public async getUserByApiKey(): Promise<User> {
        const { user, subscription } = await lastValueFrom(
            this.http.get<GetUserByApiKey>(this.getCurrentUserUrl)
        );
        this.store.dispatch(new LoadUserActiveSubscriptions(subscription.is_active));
        this.store.dispatch(new SetUser(user));
        this.store.dispatch(new SetUsername(user.full_name));
        this.setUserLocale(user);

        return user;
    }

    public async getUserHours(
        startDate: string,
        endDate: string,
        user_id: string,
        days_of_form_editing_window?: string
    ): Promise<Array<Form>> {
        const headers = {
            params: {
                include: "projects,form_templates,time_entries.time_entry_types",
                sort: "user_forms",
                direction: "desc",
                date_from: startDate,
                date_to: endDate,
                user_id,
                limit: "500",
            },
        };

        let { data } = await lastValueFrom(
            this.http.get<FormsResponseModel>(this.formsUrl, headers)
        ).catch((err) => {
            sentryCaptureException(err);
            this.toastService.showToastMessage("TOAST.BAD_CONNECTION");
            return { data: [] };
        });

        data = data.filter((x) => x.is_draft === false);

        if (startDate && endDate) {
            const formsInDateRange = [];
            const today = moment();
            const pipe = new SumTimeEntriesPipe();
            let currentDate: moment.Moment;
            let dayDifference: number;

            for (const form of data) {
                if (form?.time_entries?.length !== 0) {
                    form.hours = pipe.transform(form.time_entries);
                }
                if (days_of_form_editing_window) {
                    currentDate = moment(form.form_date);
                    dayDifference = today.diff(currentDate, "days");

                    form.can_edit = dayDifference <= Number(days_of_form_editing_window);
                }

                formsInDateRange.push(form);
            }

            this.store.dispatch(new SetMyFormsHours(formsInDateRange));
            return formsInDateRange;
        }

        return [];
    }

    public async getUserHoursForCurrentWeek(): Promise<void> {
        this.dateTimeService.getCurrentWeekFirstAndLastDate();
        const headers = {
            params: {
                date_from: moment(this.dateTimeService.weekStartDate).format("YYYY-MM-DD"),
                date_to: moment(this.dateTimeService.weekEndDate).format("YYYY-MM-DD"),
            },
        };

        const { data } = await lastValueFrom(
            this.http.get<UserHoursResponseModel>(this.getUserHoursUrl, headers)
        );

        const total_hours = data.total_hours ?? 0;
        this.store.dispatch(new SetUserDailyHours(data.daily_hours));
        this.store.dispatch(new SetUserHours(total_hours.toString()));
    }

    public async getCompanyEmployees(page = 1, query = ""): Promise<User[]> {
        const headers = {
            params: {
                page: page.toString(),
                q: query,
                is_active: "true",
                include: "languages",
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<UserReponseModel>(this.getUsersUrl, headers)
        );

        if (page === 1) {
            this.store.dispatch(new ClearUsers());
        }

        this.store.dispatch(new LoadUsers(data));
        this.store.dispatch(new LoadUsersPagination(pagination));
        return data;
    }

    public getGoogleStaticMapUrl(coords: any): string {
        return `${appConstants.googleMapsStaticUrl}&markers=color%3Ared%7C${coords.latitude},${coords.longitude}`;
    }

    public async checkIn(
        projectId: string,
        lat: number,
        long: number,
        time: Date = null
    ): Promise<SuccessAdd> {
        const body = {
            project_id: projectId,
            checkin_latitude: lat,
            checkin_longitude: long,
            checked_in: time,
        };

        return await lastValueFrom(this.http.post<SuccessAdd>(this.checkInURL, body));
    }

    public async checkOut(lat: number, long: number, time: Date = null): Promise<SuccessAdd> {
        const url = `${this.checkInURL}/checkout`;
        const body = {
            checkout_latitude: lat,
            checkout_longitude: long,
            checked_out: time,
        };

        return await lastValueFrom(this.http.post<SuccessAdd>(url, body));
    }

    public async isUserCheckedIn(): Promise<ClockingRecord> {
        let isCheckedIn = false;
        const headers = {
            params: {
                active: "1",
            },
        };

        const { data } = await lastValueFrom(
            this.http.get<ClockingRecordsResponseModel>(this.checkInURL, headers)
        );

        if (data?.length !== 0) {
            isCheckedIn = true;
        }

        this.store.dispatch(new SetUserCheckIn(isCheckedIn));
        this.store.dispatch(new SetUserClockingRecords(data));

        return data[0];
    }

    public deleteClockingRecord(id: string): Promise<GeneralSuccessResp> {
        return lastValueFrom(this.http.delete<GeneralSuccessResp>(`${this.checkInURL}/${id}`));
    }

    public async uploadUserImage(userId: string, file: File) {
        const body = new FormData();

        body.append("image", file, file.name);

        return await lastValueFrom(
            this.http.post<SendFileToBucketResponseModel>(
                `${this.getUsersUrl}/${userId}/uploadImage`,
                body
            )
        );
    }

    public async updateUser(id: string, body: Partial<User>) {
        const formData = new FormData();

        for (const key of Object.keys(body)) {
            if (key === "image" && body[key] && body[key].name) {
                formData.append(key, body[key], body[key].name);
            } else {
                formData.append(key, body[key]);
            }
        }

        const url = `${this.getUsersUrl}/${id}`;

        const data = lastValueFrom(
            this.http.put<UpdateUserResponseModel | ErrorResponseModel>(url, body)
        )
            .then((resp) => resp as UpdateUserResponseModel)
            .catch(({ error }) => error as ErrorResponseModel);

        return await data;
    }

    public async getAllUsersCount(): Promise<number> {
        const headers = {
            params: {
                page: "1",
                limit: "1",
                is_active: "true",
            },
        };

        const { pagination } = await lastValueFrom(
            this.http.get<UserReponseModel>(this.getUsersUrl, headers)
        );

        return pagination.count;
    }

    public async getAllUsers(
        page = 1,
        searchQuery = "",
        includes = "",
        sort = "",
        direction = "",
        is_active = "true",
        limit: string = null,
        useStore = true
    ): Promise<User[]> {
        const headers = {
            params: {
                page: page.toString(),
                q: searchQuery,
                include:
                    includes ||
                    "languages,cities,integration_settings.integrations,integration_settings.integrations.integration_types,roles",
                sort,
                direction,
                is_active,
                limit,
            },
        };

        if (!is_active) {
            delete headers.params.is_active;
        }

        if (!limit) {
            delete headers.params.limit;
        }

        const { data, pagination } = await lastValueFrom(
            this.http.get<UserReponseModel>(this.getUsersUrl, headers)
        );

        if (page === 1 && useStore) {
            this.store.dispatch(new ClearUsers());
        }

        this.store.dispatch(new LoadUsersPagination(pagination));

        if (useStore) {
            this.store.dispatch(new LoadUsers(data));
        }

        return data;
    }

    public getUserById(id: string, include?: string): Promise<UpdateUserResponseModel> {
        const headers = {
            params: {},
        };

        if (include) {
            headers.params = {
                ...headers.params,
                include,
            };
        }
        return lastValueFrom(
            this.http.get<UpdateUserResponseModel>(`${this.getUsersUrl}/${id}`, headers)
        );
    }

    private setUserLocale(user: User): void {
        const overrideLocales = ["ru", "nb", "pl"];
        let userLocale = user?.language?.short_code ? user.language.short_code.split("_")[0] : "en";

        if (overrideLocales.includes(userLocale)) {
            userLocale = "da";
        }

        this.store.dispatch(new SetUserLocale(userLocale));
    }
}
